import React, { useEffect } from 'react';
import {
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_USE_TIP_TAP_OOI,
} from '@wix/communities-blog-experiments';
import { connect, useSelector } from '../../../common/components/runtime-context';
import { WixRicosViewerLazy } from '../rich-content-viewer/wix-ricos-viewer-lazy';
import { WixRicosViewerNextLazy } from '../rich-content-viewer/wix-ricos-viewer-next-lazy';

const RichContentViewerLoader = () => {
  const rceNext = useSelector((state) =>
    isExperimentEnabled(state, EXPERIMENT_USE_TIP_TAP_OOI),
  );
  useEffect(() => {
    if (rceNext) {
      WixRicosViewerNextLazy.preload();
    } else {
      WixRicosViewerLazy.preload();
    }
  }, [rceNext]);

  return <React.Fragment />;
};

export default RichContentViewerLoader;
